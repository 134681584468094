.clients{
    margin: 20px;
    max-width: 750px;
}

header{
    margin:3vh;
    text-align: center;
    background-image: url(https://images.unsplash.com/photo-1468779036391-52341f60b55d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1521&q=80);
    background-size: cover;
    height: 50vh;
}

h2{
    text-align: center;
}

.center-content{
    margin: 0 auto;
    display: flex;
}

input{
    width: 50%;
    max-width: 50%;
    padding: 15px;
    border-radius: 3px;
    margin: 13vh auto;
    transform: translate(3vh,7vw);
}

svg{
    display: none;
}

input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
    transform: translate(18vh, 0.2vw);
}

label {
    display: flex !important;
    margin-bottom: .5rem;
}

span.ais-RefinementList-count{
    padding-left: 20px;
}

.card-body .ais-RefinementList-searchBox{
    width: 25vw !important;
    transform: translate(5vh, 1vw) !important;
}

.ais-SearchBox .ais-SearchBox-input{
    width: 15vw !important;
    transform: translate(5vh, vw) !important;
}

.card{
    width:50vw;
}



